import './App.css';
import '@react95/icons/icons.css';
import React from 'react';
import { ClippyProvider, useClippy } from '@react95/clippy';
import { Button, Input, TaskBar, TextArea, ThemeProvider } from '@react95/core';

const Clippy = () => {
  const {clippy} = useClippy();
  return <Button onClick={()=>clippy.play('Wave')}>Hello Clippy!</Button>;
}

function App() {
  const [text, setValue] = React.useState('');
  return (
    <ThemeProvider>
      <ClippyProvider>
        <Clippy/>
      </ClippyProvider>
      <TextArea rows={10} cols={50} value={text} onChange={
        ({target: {value}} : React.ChangeEvent<HTMLTextAreaElement>) => setValue(value)}/>
      <Input/>
      <TaskBar/>
    </ThemeProvider>
  );
}

export default App;
